body {
  margin: 0;
  font-family: 'Sunflower', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 18px
}

div.content-half {
  width: 39.9%;
  height: 100vh;
  display: flex;
  align-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 5%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

div.new-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
}

@media only screen and (max-width: 900px) {
  div.content-half {
    width: 100%;
    height: auto;
    position: static;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 0em;
}
